<template>
  <b-overlay :show="loading" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <feather-icon icon="ClockIcon" size="24" />
        <p id="cancel-label">Please wait...</p>
      </div>
    </template>
    <header v-if="myGudang">
      <h5>
        <strong>Lokasi Gudang: {{ this.myGudang.data.nama_gudang }}</strong>
      </h5>
    </header>
    <b-row>
      <b-col md="3" sm="12">
        <p><strong>Tanggal</strong></p>
        <p class="mb-2">{{ so.tanggal }}</p>
        <section class="mt-1" v-if="so.gm">
          <p><strong>General Manager</strong></p>
          <p>
            {{ so.gm.nama_lengkap }}
          </p>
        </section>
      </b-col>
      <!-- <b-col md="3" sm="12">
            <p><strong>No</strong></p>
            <p>{{ so.no }}</p>
          </b-col> -->
      <b-col md="3" sm="12">
        <p><strong>Keterangan</strong></p>
        <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
      </b-col>
      <b-col md="3" sm="12" v-if="so.status == 0">
        <p><strong>Status</strong></p>
        <b-badge variant="light-danger" size="sm">Belum Selesai</b-badge>
      </b-col>
      <b-col md="3" sm="12" v-if="so.status == 1">
        <p><strong>Status</strong></p>
        <b-badge variant="light-success" size="sm">Selesai</b-badge>
      </b-col>
      <!-- Info Barang -->
      <!-- / -->
    </b-row>

    <section v-if="isGM && !so.id_gm" class="d-flex justify-content-end my-2">
      <b-button variant="primary" size="sm" @click.prevent="approve">Approve Stok Opname</b-button>
    </section>

    <b-row>
      <!-- Input Stock Opname -->
      <b-col cols="12">
        <b-card style="overflow-y: auto; max-height: 500px">
          <b-table small bordered striped sticky-header="400px" responsive :fields="fields" :items="so.rincian"
            class="mb-2">
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(stok)="{ item }">
              {{ item.stok }}
              <!-- <b-form-input type="number" v-model="item.stok" /> -->
            </template>
            <template #cell(kode)="{ item }">
              {{ item.barang ? item.barang.kode : "-" }}<br>
              <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
            </template>
            <template #cell(nama)="{ item }">
              {{ item.barang ? item.barang.varian : "-" }}<br>
              <small>{{ item.barang.kategori.kategori }}</small> /
              <small>
                {{ item.barang ? item.barang.nama : "-" }}
              </small> <br>
              <small>{{ $t('Room') }} : </small>
              <small>{{ item.blok ? item.blok.blok : "-" }}</small>
              <small>{{ item.palet ? item.palet.palet : "-" }}</small>
              <small>{{ item.rak ? item.rak.rak : "-" }}</small>
              <small>{{ item.laci ? item.laci.laci : "-" }}</small>

            </template>
            <!-- <template #cell(stok_real)="{ item, index }">
              <b-form-input v-model="item.stok_realComputed" type="number" />
            </template> -->
            <template #cell(so)="{ item }">
              {{ item.id_stok_opname }}
            </template>
            <template #cell(selisih)="{ item }">
              {{ item.stok_real - item.stok_in }}
              <p>
                <small class="text-info" v-if="item.stok < item.stok_realComputed"><i>(Stok Hasil SO lebih)</i></small>
                <small class="text-success" v-if="item.stok == item.stok_realComputed"><i>(Stok sesuai)</i></small>
                <small class="text-danger" v-if="item.stok > item.stok_realComputed"><i>(Stok Hasil SO
                    kurang)</i></small>
              </p>
            </template>
            <template #cell(kode2)="{ item }">
              {{ item.penyimpanan.barang ? item.penyimpanan.barang.kode : "-" }}<br>
              <small>{{ item.penyimpanan.barang ? item.penyimpanan.barang.no_batch : "-" }}</small>
            </template>
            <template #cell(nama2)="{ item }">
              {{ item.penyimpanan.barang ? item.penyimpanan.barang.varian : "-" }}<br>
              <small>{{ item.penyimpanan.barang.kategori.kategori }}</small> /
              <small>
                {{ item.penyimpanan.barang ? item.penyimpanan.barang.nama : "-" }}
              </small> <br>
              <small>{{ $t('Room') }} : </small>
              <small>{{ item.penyimpanan.blok ? item.penyimpanan.blok : "-" }}</small>
              <small>{{ item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-" }}</small>
              <small>{{ item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-" }}</small>
              <small>{{ item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-" }}</small>

            </template>
          </b-table>
        </b-card>
        <section class="my-2 d-flex justify-content-end" v-if="alreadyApproved">
          <b-button variant="primary" @click.prevent="submit">Simpan Penyesuaian</b-button>
        </section>
      </b-col>
      <!-- / -->
      <!-- {{ this.items }} -->
    </b-row>
    <footer class="d-flex justify-content-end">
      <b-button v-if="so.status == 0" variant="primary" @click.prevent="save">Simpan</b-button>
    </footer>
  </b-overlay>
</template>
<script>
import {
  BRow, BIcon,
  BCol,
  BButton,
  BFormCheckbox,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow, BIcon,
    BCol,
    BButton,
    BFormCheckbox,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
  },
  data() {
    return {
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan_id: [],
      stok_real: 0,
      stok: 0,
      stok_opname_id: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [{
        key: "no",
        label: "No",
      },
      {
        key: "kode2",
        label: "Kode/SKU",
        sortable: true
      },
      {
        key: "nama2",
        label: "Product",
        sortable: true
      },
      {
        key: "stok_in",
        label: "Stok",
      },
      // {
      //   key: "penyimpanan.barang.satuan.satuan",
      //   label: "satuan",
      // },
      {
        key: "stok_real",
        label: "Hasil Stok Opname",
      },
      {
        key: "selisih",
        label: "selisih barang",
      }
      ],
      fieldsCabang: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "barang.nama",
          label: "Nama Barang",
          sortable: true
        },
        {
          key: "blok.blok",
          label: "Blok",
          sortable: true
        },
        {
          key: "palet.palet",
          label: "palet",
        },
        {
          key: "rak.rak",
          label: "rak",
        },
        {
          key: "laci.laci",
          label: "laci",
        },
        {
          key: "jumlah",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "stok_real",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        }
      ],
      items: []
    };
  },
  async mounted() {
    // this.getData()
    await this.getDataSo()
    // this.setField()
    if (this.$route.params.id) {
      this.stok_opname_id = this.$route.params.id;
    }
  },
  computed: {
    alreadyApproved() {
      return this.isGM && this.so.id_gm
    }
  },
  methods: {
    async submit() {
      // this.items = this.items.map(item => ({
      //   // id: item.id,
      //   stok_opname_id: this.$route.params.id,
      //   penyimpanan_id: item.id,
      //   stok_in: item.jumlah,
      //   stok_real: item.stok_real,
      //   // penyesuaian: item.penyesuaian
      // }))
      let payloads = []
      this.items.map(item => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.stok_real,
        })
      })

      try {
        this.loading = true
        await this.$store.dispatch('tokostokopname/saveSo', payloads)
        await this.displaySuccess({
          message: 'Stok Opname berhasil disimpan'
        })
        await this.getDataSo()
        this.loading = false
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    updatePenyesuaian(index) {
      this.items[index].penyesuaian = this.items[index].jumlah - this.items[index].stok_real
    },
    setField() {
      if (this.alreadyApproved) {
        this.fields = [
          {
            key: 'checkbox',
            label: '#'
          },
          {
            key: "no",
            label: "No",
          },
          {
            key: "detail.nama",
            label: "Nama Barang",
            sortable: true
          },
          {
            key: "detail.blok",
            label: "Blok",
            sortable: true
          },
          {
            key: "detail.palet",
            label: "palet",
          },
          {
            key: "stok",
            label: "Stok",
          },
          {
            key: "detail.satuan",
            label: "satuan",
          },
          {
            key: "stok_real",
            label: "Hasil Stok Opname",
          },
          {
            key: "selisih",
            label: "selisih barang",
          },
          {
            key: 'penyesuaian',
            label: 'Penyesuaian'
          }
        ]
      }
      if (this.isCabang) {
        this.fields = this.fieldsCabang
      }
    },
    approve() {
      this.$swal({
        title: 'Peringatan',
        text: 'Dengan menyetujui stock opname ini, anda dapat melakukan penyesuaian hasilnya',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false
      })
        .then(async res => {
          if (res.value) {
            const payload = {
              id: this.$route.params.id,
              id_gudang: this.so.id_gudang,
              tanggal: this.so.tanggal,
              id_karyawan: this.so.id_karyawan,
              id_gm: this.user.karyawan ? this.user.karyawan.id : null
            }

            try {
              this.loading = true

              await this.$store.dispatch('tokostokopname/save', [payload])
              await this.getDataSo()
              this.displaySuccess({
                message: 'Stok opname sudah disetujui!'
              })

              this.loading = false
            }
            catch (e) {
              this.loading = false
              this.displayError(e)
              return false
            }
          }
        })
    },
    resetform() {
      this.item.stok_real = 0;
      this.stok_real = 0;
    },
    async save() {
      if (this.id) {
        this.id = this.id
      }
      let payloads = []
      this.items.map(item => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.stok_real
        })
      })
      let payload1 = {
        status: this.status
      }
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }
      try {
        this.loading = true
        await this.$store.dispatch('tokostokopname/saveSo', payloads)
        // await this.$store.dispatch('tokostokopname/save', [payload1])
        this.loading = false

        this.displaySuccess({
          text: 'Stok Opname berhasil disimpan!'
        })

        this.resetForm();
        // this.getRincian();

      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getData() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        // start: currentPage,
        // length: this.perPage,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("tokopenyimpanan/getData", payload)
      let items = JSON.parse(JSON.stringify(this.$store.state.tokopenyimpanan.datas));
      let items_total = this.$store.state.tokopenyimpanan.totals;
      this.items = items;
      // this.items = items;
      this.totalRows = items_total;
      // this.totalRows = JSON.parse( JSON.stringify(stocks) ).length
    },
    async getDataSo() {
      this.loading = true
      this.$store
        .dispatch("tokostokopname/getDataById", this.$route.params.id)
        .then((response) => {
          // const so = await this.$store
          //   .dispatch("tokostokopname/getDataById", this.$route.params.id);
          this.so = response;
          // this.so.rincian.sort((a, b) => b.stok - a.stok);
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.displayError(e);
          return false;
        });
    }
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          stok_real: 0,
          isFinalStock: false,
        });
      }
    },
  },
};
</script>
